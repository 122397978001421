import React from "react";
import styles from "../service.module.css";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import BrochureDwnld from "../../../components/BrochureDwnld";
import { Helmet } from "react-helmet";
import ContactStrip from "../../../components/ContactStrip";

const ForOrganisation = () => {
  const optImproviu = [
    {
      id: 1,
      title:
        "Avoid delays in the hiring process due to lack of interview panel",
    },
    { id: 2, title: "40% of the Hiring Managers time saved" },
    { id: 3, title: "30% of Hiring Cost saved" },
    { id: 4, title: "25% Increase in Quality of hires" },
    { id: 5, title: "Identify fake candidates by our robust process" },
    {
      id: 6,
      title:
        "Video recordings to playback and check the quality of the candidate to multiple stakeholders",
    },
    {
      id: 7,
      title:
        "Avoid repetitive questions for the next round of interview with the candidates shortlisted",
    },
    {
      id: 8,
      title:
        "Compulsory and comprehensive feedback by Interview panels along with Skill ratings",
    },
    {
      id: 9,
      title: "Client can rate the interview panels and offer suggestion",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Hire Better, Faster: Outsource Interviews with Improviu</title>
        <meta
          name="description"
          content="Streamline your hiring process with Improviu's interview outsourcing. Enjoy expert panels, video recordings, comprehensive feedback, and cost savings. Avoid delays and hire top talent faster."
        />
        <meta
          name="keywords"
          content="Interview oursource, Video Recording, Comprehensive interview feedback, Interview analytics, Pay per interview, Professional Feedback process "
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/organisations.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
        <h1>For Organisations</h1>
      </div>
      <div className="mt-4 mb-5">
        <Container>
          <p className="textJustify">
            As per extensive market surveys done by Improviu it was observed
            that:
          </p>
          <ul>
            <li>
              40% of the hiring manager time is wasted in interviewing
              candidate.
            </li>
            <li>
              75% of such candidate interviewed are rejected by the hiring
              managerr.
            </li>
          </ul>
          <p className="textJustify">
            Improviu evolved Interview outsourcing process and facilitated the
            1st level interviews to many organisation and as a result these
            organisation as have benefited largely.
          </p>
        </Container>
        <div>
          <Container>
            <div className="text-center">
              <h5 className="mt-4 mb-3 heading-underline">Key Highlights</h5>
            </div>
            <p className="textJustify">
              For many of our clients, this is a dream situation since their
              hiring pain points and recruitment timelines are reduced. The
              hiring managers get more productive by focusing on key deliverable
              rather than conducting multiple rounds of interviews to shortlist
              candidates (We do this job for them).
            </p>
            <Row>
              <Col
                md={4}
                className="d-flex justify-content-center mb-3 mb-md-0"
              >
                <Card className={styles.impCard}>
                  <Card.Body className="d-flex justify-content-center align-items-center text-center">
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      Improviu Interview Panel consists of skilled individuals
                      with 5 to 30 years of industry experience and expertise in
                      their fields.
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                md={4}
                className="d-flex justify-content-center mb-3 mb-md-0"
              >
                <Card className={styles.impCard}>
                  <Card.Body className="d-flex justify-content-center align-items-center text-center">
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      With over 50+ different skills set currently for Improviu
                      process with most of the key IT Skills are addressed.
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="d-flex justify-content-center">
                <Card className={styles.impCard}>
                  <Card.Body className="d-flex justify-content-center align-items-center text-center">
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      We can facilitate the required skill sets if not available
                      with us as per your need within 1 weeks’ time on the
                      interview panels.
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div className="text-center">
              <h5 className="mt-4 mb-3 heading-underline">
                Here are the reasons Organisations should opt for “Improviu”
              </h5>
            </div>
            <Row className="g-3 justify-content-center">
              {optImproviu?.map(({ id, title }) => (
                <Col key={id} md={4} className="d-flex justify-content-center">
                  <Card className={styles.impCard}>
                    <Card.Body className="d-flex justify-content-center align-items-center text-center">
                      <Card.Title
                        style={{
                          fontSize: "14px",
                          marginBottom: "0px",
                        }}
                      >
                        {title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div className="text-center">
              <h5 className="mt-4 mb-3 heading-underline">
                Difference between Inhouse hiring Process and Improviu Hiring
                process
              </h5>
            </div>
            <Table bordered striped>
              <thead className={styles.tblHead}>
                <tr>
                  <th className="text-center">Inhouse Hiring process</th>
                  <th className="text-center">
                    Hiring process with Interview as a service
                  </th>
                </tr>
              </thead>
              <tbody className={styles.tblBody}>
                <tr>
                  <td>Zero reference of interviews conducted</td>
                  <td>
                    Video Interview stored for up to 90 days for reference
                  </td>
                </tr>
                <tr>
                  <td>Basic Feedback from Panel</td>
                  <td>
                    Candidate Rating on skill, Detailed feedback from Experts
                  </td>
                </tr>
                <tr>
                  <td>Uncertain Candidate Experience of Interview</td>
                  <td>
                    Structured Interview with Pleasant candidate experience
                  </td>
                </tr>
                <tr>
                  <td>Hiring manager / Company Panel Interview</td>
                  <td>Industry Experts interview</td>
                </tr>
                <tr>
                  <td>Standard Company Interview process</td>
                  <td>Global interview process</td>
                </tr>
                <tr>
                  <td>Average time-to-hire: 30 - 90 days</td>
                  <td>Average time-to-hire: 15 - 30 Days</td>
                </tr>
                <tr>
                  <td>Cost per interview higher</td>
                  <td>Cost efficient Interviews</td>
                </tr>
                <tr>
                  <td>Slow hiring process due to delay in Feedback</td>
                  <td>40% faster hiring process</td>
                </tr>
                <tr>
                  <td>Fake candidates not identified</td>
                  <td>Robust process of Identifying Fake candidates</td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </div>
        <div className="mt-4">
          <BrochureDwnld />
        </div>
        <div className="mb-4">
          <ContactStrip />
        </div>
      </div>
    </>
  );
};

export default ForOrganisation;
