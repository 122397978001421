import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import ContactStrip from "../../components/ContactStrip";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Pricing = () => {
  const navigate = useNavigate();

  const contactNaviate = () => {
    navigate("/contact-us");
  };

  let pricingBtn = {
    background: "whitesmoke",
    borderColor: "whitesmoke",
    color: "black",
    fontWeight: "bold",
  };

  return (
    <>
      <Helmet>
        <title>
          Flexible Pricing for Interview Outsourcing: Choose Your Plan
        </title>
        <meta
          name="description"
          content="Looking for affordable interview outsourcing? Explore our three pricing tiers: Essential, Superior, and Infinite. Find the perfect plan to streamline your hiring process and save time."
        />
        <meta
          name="keywords"
          content="Business enquiry, Interview pricing, Affordable interview outsourcing, annual subscription, Volume interview pricing, Video interview pricing, Outsoured interview cost, Pricing for Interview as a service, Mock interview cost"
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/pricing-bg.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
        <h1>Pricing</h1>
      </div>
      <div className="mt-4">
        <Container>
          <h2 className="text-center mt-5">
            We offer Amazing plans suiting your unlimited needs
          </h2>
          <p className="text-center mt-3 pb-3" style={{ fontSize: "16px" }}>
            Select a Plan tailored to your Requirements. Contact us for Best
            Pricing OR Book a Demo slot to experience Improviu
          </p>
        </Container>
        <div>
          <Container>
            <Row>
              <Col md={4} className="mb-3 p-4">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 10px 10px 10px",
                    height: "260px",
                    background: "#ffbc03",
                    color: "black",
                    border: "none",
                  }}
                >
                  <Card.Body className="text-center fw-medium d-flex flex-column justify-content-between align-items-center">
                    <Card.Title style={{ fontSize: "2.8rem" }}>
                      Essential
                    </Card.Title>
                    <Card.Text style={{ fontSize: "16px" }}>
                      3 Months Trial Subscription Plan
                    </Card.Text>
                    <Button onClick={contactNaviate} style={pricingBtn}>
                      Get Quote
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-3 p-4">
                <Card
                  className=""
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 10px 10px 10px",
                    height: "260px",
                    background: "#37a756",
                    color: "black",
                    border: "none",
                  }}
                >
                  <Card.Body className="text-center fw-medium d-flex flex-column justify-content-between align-items-center">
                    <Card.Title style={{ fontSize: "2.8rem" }}>
                      Superior
                    </Card.Title>
                    <Card.Text style={{ fontSize: "16px" }}>
                      Annual Subscription Plan (1 year)
                    </Card.Text>
                    <Button onClick={contactNaviate} style={pricingBtn}>
                      Get Quote
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-3 p-4">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 10px 10px 10px",
                    height: "260px",
                    background: "#1866d1",
                    color: "black",
                    border: "none",
                  }}
                >
                  <Card.Body className="text-center fw-medium d-flex flex-column justify-content-between align-items-center">
                    <Card.Title style={{ fontSize: "2.8rem" }}>
                      Infinite
                    </Card.Title>
                    <Card.Text style={{ fontSize: "16px" }}>
                      Customised Pricing For Volume Interviews
                    </Card.Text>
                    <Button onClick={contactNaviate} style={pricingBtn}>
                      Get Quote
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <ContactStrip />
      </div>
    </>
  );
};

export default Pricing;
