import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { MdMail, MdPhone } from "react-icons/md";
import axios from "axios";
import ContactStrip from "../../components/ContactStrip";
import { IoLocation } from "react-icons/io5";
import {
  FaLinkedin,
  FaSquareFacebook,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { SiInstagram } from "react-icons/si";
import BrochureDwnld from "../../components/BrochureDwnld";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
    role: "",
  });

  const [errors, setErrors] = useState({
    fname: "",
    email: "",
    phone: "",
    role: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [touched, setTouched] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!formData.fname.trim()) {
      newErrors.fname = "First name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.role) {
      newErrors.role = "Role is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [formData]); //eslint-disable-line

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = () => {
    setTouched(true);
    validate();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      // form submission
      try {
        setSubmitting(true);
        const res = await axios.post(
          "https://improviu.co.in/email.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data === "Success") {
          setSuccessMessage(
            "Thank you for contacting us! We will get back to you soon."
          );
          setFormData({
            fname: "",
            lname: "",
            email: "",
            phone: "",
            role: "",
            message: ""
          });
          setErrors({
            fname: "",
            email: "",
            phone: "",
            role: "",
          });
          setTouched(false);
          setSubmitting(false);
        }
      } catch (error) {
        setErrorMessage("Error  occurred, please try again later");
        setFormData({
          fname: "",
          lname: "",
          email: "",
          phone: "",
          role: "",
          message: ""
        });
        setErrors({
          fname: "",
          email: "",
          phone: "",
          role: "",
        });
        setTouched(false);
        setSubmitting(false);
      }
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 2000);
    }
  };

  return (
    <>
      <Helmet>
        <title>To Improve Your Interviewing Skills Contact Us Now!!</title>
        <meta
          name="description"
          content="Want to improve your interviewing skills? Contact Improviu for mock interviews, expert feedback, and personalized coaching."
        />
        <meta
          name="keywords"
          content="Contact Improviu, Contact us,Improve Your Interviewing Skills, Call us, Contact us for Video interviewing, looking interview outsourcing services, contact us for Freelance interviewing, Contact us for Mock interview"
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/contact-us-bg.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
        <h1>Contact Us</h1>
      </div>
      <div className="mt-4">
        <Container>
          <h5 className="text-center">
            “Improviu” from ResourceTree Global Services Pvt Ltd
          </h5>
          <Row className="mt-4">
            <Col
              md={6}
              className="d-flex flex-column  justify-content-center gap-4"
            >
              <div>
                <h4 className="fw-medium pb-2 text-center">
                  Request for Demo / Call back
                </h4>
                <h6 className="text-center">
                  <p style={{ marginBottom: "2px" }}>
                    We welcome your inquiries and feedback.
                  </p>
                  <p style={{ marginBottom: "2px" }}>
                    Please feel free to contact us with questions or comments.
                  </p>
                  <p style={{ marginBottom: "2px" }}>
                    Our team will respond to you promptly.
                  </p>
                </h6>
              </div>
              <div className="text-center">
                <h5>
                  <MdMail
                    style={{ color: "#2362ba", fontSize: "20px" }}
                    className="me-2"
                  />
                  <a
                    href="mailto:info@improviu.co.in"
                    className="text-decoration-none text-dark"
                    style={{ fontSize: "15px" }}
                  >
                    info@improviu.co.in
                  </a>
                </h5>
                <h5>
                  <MdPhone
                    style={{ color: "#2362ba", fontSize: "20px" }}
                    className="me-2"
                  />
                  <span style={{ fontSize: "15px" }} className="text-dark">
                    (+91) 6366013922
                  </span>
                </h5>
                <h5>
                  <IoLocation
                    style={{ color: "#2362ba", fontSize: "20px" }}
                    className="me-2"
                  />
                  <span style={{ fontSize: "15px" }} className="text-dark">
                    Bangalore, Karnataka
                  </span>
                </h5>
              </div>
              <div className="pb-2 pb-md-0 text-center">
                <h6 style={{ color: "#2362ba", marginBottom: "0px" }}>
                  Follow us on social media
                </h6>
                <div className="d-flex flex-wrap gap-2 mt-2 justify-content-center">
                  <a
                    href="https://www.facebook.com/Resourcetree"
                    target="_blank"
                    rel="noreferrer"
                    className={"icon fb"}
                    aria-label="Facebook"
                  >
                    <FaSquareFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/resourcetreee/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon insta"
                    aria-label="insta"
                  >
                    <SiInstagram />
                  </a>
                  <a
                    href="https://twitter.com/improviu"
                    target="_blank"
                    rel="noreferrer"
                    className="icon twitter"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://www.youtube.com/@Improviu"
                    target="_blank"
                    rel="noreferrer"
                    className="icon youtube"
                    aria-label="YouTube"
                  >
                    <FaYoutube />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/improviu"
                    target="_blank"
                    rel="noreferrer"
                    className="icon linkedin"
                    aria-label="LinkedIn"
                  >
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} className="card shadow p-4">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: "15px" }}>
                        First Name{" "}
                        <span className="text-danger fw-bold">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                        name="fname"
                        value={formData.fname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched && !!errors.fname}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched && errors.fname}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: "15px" }}>
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your last name"
                        name="lname"
                        value={formData.lname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: "15px" }}>
                        Email <span className="text-danger fw-bold">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched && !!errors.email}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched && errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: "15px" }}>
                        Phone Number{" "}
                        <span className="text-danger fw-bold">*</span>
                      </Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter your phone number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched && !!errors.phone}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched && errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: "15px" }}>
                        Role <span className="text-danger fw-bold">*</span>
                      </Form.Label>
                      <div className="d-flex flex-wrap gap-4">
                        <Form.Check
                          type="radio"
                          label="Organization"
                          name="role"
                          value="Organization"
                          checked={formData.role === "Organization"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched && !formData.role && errors.role}
                          required
                          className="fw-medium"
                        />
                        <Form.Check
                          type="radio"
                          label="Interviewer"
                          name="role"
                          value="Interviewer"
                          checked={formData.role === "Interviewer"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched && !formData.role && errors.role}
                          required
                          className="fw-medium"
                        />
                        <Form.Check
                          type="radio"
                          label="Candidate"
                          name="role"
                          value="Candidate"
                          checked={formData.role === "Candidate"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched && !formData.role && errors.role}
                          required
                          className="fw-medium"
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {touched && errors.role}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontSize: "15px" }}>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <div className="d-flex">
                  <Container fluid>
                    <Row>
                      <Col md={10} className="d-flex align-items-center">
                        {successMessage && (
                          <p className="mb-0" style={{ fontSize: "14px" }}>
                            {successMessage}
                          </p>
                        )}
                        {errorMessage && (
                          <p
                            className="mb-0"
                            style={{ fontSize: "14px", color: "red" }}
                          >
                            {errorMessage}
                          </p>
                        )}
                      </Col>
                      <Col md={2} className="pe-0 d-flex justify-content-end">
                        <Button
                          style={{ fontSize: "15px" }}
                          variant="success"
                          type="submit"
                          disabled={Object.keys(errors).length > 0}
                        >
                          {submitting ? "Submitting..." : "Submit"}
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-3" style={{ maxWidth: "100%", overFlow: "hidden" }}>
        <iframe
          title="Improviu"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.199495764246!2d77.59203886887825!3d13.022964385702656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17df129b13d3%3A0xfe1445513ec4ebbf!2s6th%20Main%20Rd%2C%20RT%20Nagar%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1723631225862!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: "0" }}
          loading="lazy"
        ></iframe>
      </div>
      <div className="mt-4">
        <BrochureDwnld />
      </div>
      <div className="mb-4">
        <ContactStrip />
      </div>
    </>
  );
};

export default ContactUs;
