import React from "react";
import { Button, Modal } from "react-bootstrap";

const DisclamerModal = ({ modalShow, setModalShow }) => {
  const handleClose = () => {
    setModalShow(false);
  };
  return (
    <>
      <Modal
        show={modalShow}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="fs-6 pt-0 pb-0 ps-2"
          >
            CAUTION NOTICE REGARDING FRAUDULENT JOB / INTERNSHIP OFFERS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p className="textJustify">
            Brand Improviu is an Interview outsourcing Online Platform from
            ResourceTree Global services Pvt Ltd.
          </p>
          <p className="textJustify">
            Name “Improviu” Represents the Online platform name and brand for
            interview as a service from ResourceTree Global Services Pvt Ltd.
          </p>
          <p className="textJustify">
            This is to notify that some person (s) have been posing and
            misrepresenting themselves as employee(s) / representative(s) /
            agent(s) / authorised person(s) of{" "}
            <span className="fw-bold">“Improviu”</span>, ResourceTree Global
            Services Pvt Ltd. and/or ResourceTree and are fraudulently offering
            / soliciting job(s) / internship(s) Interview(S) Mock Interview(S)
            Fake Online interview(s) using the name / brand of{" "}
            <span className="fw-bold">“Improviu”</span>, ResourceTree Global
            Services Pvt Ltd. and/or ResourceTree.
          </p>
          <p className="textJustify">
            Please note that ResourceTree Global Services Pvt Ltd. or
            ResourceTree OR <span className="fw-bold">Improviu</span> does not
            and has never authorised any person(s) / its employee(s) /
            representative(s) / agent(s) to offer / promise / solicit any job or
            internship or Outsourced Interview for any of its clients with
            ResourceTree Global Services Pvt Ltd. or ResourceTree or Improviu or
            any of its clients, nor has authorised any employee / representative
            / agent to issue any letter(s) or act on behalf of Improviu,
            ResourceTree Global Services Pvt Ltd. or ResourceTree or use its
            name, trademark, logo, E-mail ID and / or websites for any purpose
            whatsoever.
          </p>
          <p className="textJustify">
            Websites:{" "}
            <a
              href="https://www.improviu.co.in"
              target="blank"
              rel="noreferrer"
            >
              https://www.improviu.co.in
            </a>{" "}
            |{" "}
            <a
              href=" https://www.resourcetree.co.in"
              target="blank"
              rel="noreferrer"
            >
              https://www.resourcetree.co.in
            </a>
          </p>
          <p className="textJustify">
            Further “Improviu” and / OR ResourceTree Global Services Pvt Ltd.
            and/or ResourceTree does not ask, and/or accept any monies in any
            form from the interns/trainees/job applicants/potential job seekers
            who have applied or wish to apply to us, whether online or
            otherwise.
          </p>
          <p className="textJustify">
            “Improviu”, ResourceTree Global Services Pvt Ltd. or ResourceTree
            strongly recommends and requests that any students / potential job
            seekers / any other persons exercise utmost caution while receiving
            any such job / internship offer(s) / promise(s) / solicitation(s)
            and not respond to any such offer(s) / promise(s) / solicitation(s),
            especially when the conversations are held through any unofficial
            e-mail ID(s) or on phone call(s) and not through any official
            authorised mode of communication and avoid falling prey to such
            fraudulent activities.
          </p>
          <p className="textJustify">
            Please note that all the valid and legitimate communications
            regarding recruitments / Internships or related activities such as
            Client interviews via Improviu are carried out only by Mr.
            Shivakumar N (Founder, ResourceTree Global Services Pvt Ltd. and
            ResourceTree and Improviu) and his Employees / Consultants with
            official e-mail IDs from the e-mail IDs carrying the Domain
            Improviu.co.in | resourcetree.co.in or from his official e-mail ID
            (shivakumar@resourcetree.co.in) and are not outsourced / assigned to
            any person(s) / employee(s) / representative(s) / agent(s). Being
            cautioned and aware of fake interviews which can lead to fake jobs
            is important and an action individuals need to take to check the
            authenticity of the service provider on their own.
            <span style={{ color: "#00b050" }}>
              {" "}
              Anyone dealing with any such fake / unauthorized interviews, calls
              / e-mails would be doing so at his / her / its own risk.
              ResourceTree Global Services Pvt Ltd. or ResourceTree or
              “Improviu” is and shall not be responsible and/or held liable for
              any loss or damage suffered by any person / party, directly or
              indirectly, caused due to any such fraudulent act(s) and / or any
              consequence(s) thereof.
            </span>
          </p>
          <p className="textJustify">
            In the event that you come across and / or have information
            regarding any such fraudulent offer(s) / promise(s) /
            solicitation(s), kindly write to us on shivakumar@resourcetree.co.in
            or call on (+91) 9900151392. Your assistance in providing the
            required information will help{" "}
            <span className="fw-bold">
              Improviu and ResourceTree Global Services Pvt Ltd.
            </span>{" "}
            and ResourceTree to take appropriate legal actions against such
            fraudulent / unauthorized acts and report the same to the
            appropriate authorities under the applicable laws.{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{
              background: "#000a47",
              borderColor: "#000a47",
              color: "white",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisclamerModal;
