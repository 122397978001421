import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Improviu Privacy Policy: Protecting Your Data</title>
        <meta
          name="description"
          content="Learn how Improviu protects your privacy. This policy explains what data we collect, how we use it, and your rights. We take security seriously and never sell your information."
        />
        <meta
          name="keywords"
          content="Privacy Policy, Improviu Privacy Policy
"
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/privacy-bg.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
        <h1>Privacy Policy</h1>
      </div>
      <div className="mt-4">
        <Container>
          <p className="textJustify">
            <span className="fw-bold">“Improviu”</span>{" "}
            (https//www.improviu.co.in) a Product / platform of ResourceTree
            Global Services Pvt Ltd. is committed to safeguarding the privacy of
            any person who access and uses (“Users”) our website
            https//www.improviu.co.in (“Website”). This Privacy Policy governs
            the manner in which Improviu (“Improviu”; “We”; “Us”; “Our)
            collects, uses, maintains and discloses information collected from
            Users (“you”; “User”). This privacy policy applies to any person or
            entity that access Our Website or has shared any information or data
            with Improviu. By accessing Our Website / s or using the Services,
            you accept to be bound by the i) terms and conditions; and ii)
            privacy policy of this Website. This privacy policy may be updated
            from time to time at the sole discretion of Improviu and all such
            revisions shall take effect from the day and date they are posted on
            the website. Improviu shall have no obligation to notify the User
            about the updation or changes for its website/s. You are advised to
            consult to this Privacy Policy regularly for any changes. If you do
            not agree with any part of it, please do not use the Website or
            avail any services.
          </p>
          <h3 className="mt-4 fs-5 fw-bold">INFORMATION THAT WE COLLECT</h3>
          <p className="textJustify">
            A. Personal Data/Information We may collect personal identification
            information such as full name, mobile number, email address,
            residence address or other detail as required from Users in a
            variety of ways, including, but not limited to,: i) when Users enter
            information through the online form published on our Website ii),
            respond to a survey, fill out a form iii) contact us for help or iv)
            submit any query. We will collect personal identification
            information from the User only if he/she/they voluntarily submit
            such information to us. User may choose not to provide any personal
            information to us, in such case, if any information required is
            classified as mandatory, User may not be able to avail part or whole
            of the services. If User would like us to delete any personal data
            or information provided by User with User’s consent at any time,
            User may write to us at info@improviu.co.in. In the absence of any
            expresses objection, it will be assumed that User have voluntarily
            provided the information and has given consent to use such
            information in accordance with this Privacy Policy
          </p>
          <p className="textJustify">
            B. Non-Personal Data/ Information We may record and save
            non-personal identification information about Users from our web
            server logs such as Device ID, Device make/model, Internet Protocol
            (IP) address, browser type, page views, website navigation,
            frequency of use of website, the time day User visited and User
            browser type and other similar information from our web server logs
            and Cookies. Cookies are small files that are placed in the hardware
            of the User device that captures small bits of information that are
            automatically stored on a computer/mobile web browser These
            information’s are used to verify and analyse our records in order to
            improve our website and to provide User with better and personalised
            services. User may choose to set their web browser to protect
            sharing any non- personal information, or to refuse cookies, or to
            alert User when cookies are being sent.
          </p>
          <h3 className="mt-4 fs-5 fw-bold">
            USES, DISCLOSURE AND RETENTION OF DATA / INFORMATION
          </h3>
          <p className="textJustify">
            We use the personal Information we collect to (ii) to reach out to
            you; (iii) provide services requested by you; (iv) improve the
            Website, and; (v) to provide you with a safe, efficient, and
            customized experience. Information we collect can be shared with (i)
            our employees, (ii) third party service provider (who help us
            operate our business) for those limited purposes provided that User
            have given us permission (iii) analytics providers to evaluate usage
            of our Site, and to help us improve our services, performance and
            user experiences (iv) government and law enforcement authority upon
            request. No User personal information shall be retained for a period
            longer than is necessary for the purpose for which it is collected.
            By agreeing to this Privacy Policy, you have given your consent for
            disclosure of the information we collect.
          </p>
          <h3 className="mt-4 fs-5 fw-bold">
            PROTECTION OF PERSONAL INFORMATION / DATA
          </h3>
          <p className="textJustify">
            We maintain technical, physical, electronic, procedural and
            administrative safeguard/security measure to protect your
            information against any loss, misuse and unauthorized access. While
            we are committed to protecting your personal information, no data
            transmission over the Internet can be guaranteed to be 100% secure.
            Therefore, we cannot guarantee its absolute security.
          </p>
          <h3 className="mt-4 fs-5 fw-bold">LINK TO OTHER WEBSITES</h3>
          <p className="textJustify">
            This website may include links to other websites whose privacy
            policy shall differ from Improviu. Improviu encourages you to review
            the privacy statements of these Web sites which you choose to visit
            from Improviu. Browsing and interaction with these websites is
            subject to that website’s own terms and policies. Improviu is not
            responsible for the privacy statements or other content on Web sites
            outside of the Improviu.co.in website. Improviu shall not be
            responsible for any loss, damages, claimed incurred by you by
            accessing these third-party websites.
          </p>
          <h3 className="mt-4 fs-5 fw-bold">DECLARATION</h3>
          <p className="textJustify">
            WE DO NOT SELL, TRANSFER, RENT, TRADE OR PUBLISH USER INFORMATION TO
            ANY THIRD PARTY EXCEP TO PROVIDE THE SERVICES REQUESTED BY USER.
          </p>
          <p className="textJustify">
            If you have any grievances or wish to report a security breach you
            may contact our grievance officer whose details are provided as
            below:
          </p>
          <h6>Name: Shivakumar.N</h6>
          <h6>Designation: PMO</h6>
          <h6>Email Id: Info@improviu.co.in</h6>
          <hr className="mt-4 mb-4" />
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
