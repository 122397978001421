import React from "react";
import { Image } from "react-bootstrap";

const Error = () => {
  return (
    <div>
      <Image
        className="mt-5"
        src="assets/error.png"
        alt="error"
        style={{
          height: "90vh",
          width: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
};

export default Error;
