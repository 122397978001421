import React from "react";
import { Button } from "react-bootstrap";

const BrochureDwnld = () => {
  return (
    <>
      <div
        className="brochure-download text-center my-3 p-2"
        style={{
          backgroundColor: "#ffbc03",
          borderRadius: "8px",
          boxShadow: "#ffc107 0px 4px 12px",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        <h4 className="mb-2" style={{ color: "#333", fontWeight: "bold" }}>
          Download Our Brochure
        </h4>
        <p className="mb-3" style={{ color: "#333", fontSize: "14px" }}>
          Get detailed information about our services
        </p>
        <Button
          variant="primary"
          href="/assets/pdf/broucher.pdf"
          download
          style={{
            background: "linear-gradient(135deg, #007bff, #0056b3)",
            border: "none",
            padding: "8px 16px",
            fontSize: "14px",
            fontWeight: "bold",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          Download
        </Button>
      </div>
    </>
  );
};

export default BrochureDwnld;
