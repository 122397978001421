import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdMail,
  MdPhone,
} from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { SiInstagram } from "react-icons/si";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaTwitter, FaYoutube, FaLinkedin } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { IoMdArrowDropright } from "react-icons/io";

const Footer = () => {
  const location = useLocation();

  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const handleServicesClick = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const servicePath =
    location.pathname === "/organisations" ||
    location.pathname === "/interviewers" ||
    location.pathname === "/candidates";

  return (
    <>
      <div>
        <Container fluid>
          <Row className="p-4 pt-0 pb-0 d-flex justify-content-between">
            <Col md={3} className="mt-1 mt-md-0 d-flex align-items-center">
              <Image
                src="/assets/mainlogo.png"
                alt="Improviu"
                style={{ height: 55, width: "100%", objectFit: "contain" }}
              />
            </Col>
            <Col md={3} className="mt-3 mt-md-0">
              <div>
                <h6 style={{ color: "#2362ba" }}>Get in Touch</h6>
                <h6 style={{ fontSize: "14px" }}>
                  <MdMail
                    style={{
                      color: "#2362ba",
                      fontSize: "15px",
                    }}
                  />
                  <a
                    style={{ fontWeight: "400" }}
                    href="mailto:info@improviu.co.in"
                    className="text-decoration-none text-black mx-1"
                  >
                    info@improviu.co.in
                  </a>
                </h6>
                <h6 style={{ fontSize: "14px" }}>
                  <MdPhone style={{ color: "#2362ba", fontSize: "15px" }} />
                  <span
                    className="mx-1"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    (+91) 6366013922
                  </span>
                </h6>
              </div>
              <div className="mt-3">
                <h6 style={{ color: "#2362ba", marginBottom: "0px" }}>
                  Follow us on social media
                </h6>
                <div className="d-flex flex-wrap gap-2 mt-2">
                  <a
                    href="https://www.facebook.com/Resourcetree"
                    target="_blank"
                    rel="noreferrer"
                    className={"icon fb"}
                    aria-label="Facebook"
                  >
                    <FaSquareFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/resourcetreee/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon insta"
                    aria-label="insta"
                  >
                    <SiInstagram />
                  </a>
                  <a
                    href="https://twitter.com/improviu"
                    target="_blank"
                    rel="noreferrer"
                    className="icon twitter"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://www.youtube.com/@Improviu"
                    target="_blank"
                    rel="noreferrer"
                    className="icon youtube"
                    aria-label="YouTube"
                  >
                    <FaYoutube />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/improviu"
                    target="_blank"
                    rel="noreferrer"
                    className="icon linkedin"
                    aria-label="LinkedIn"
                  >
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </Col>

            <Col md={3} className="mt-3 mt-md-0">
              <h6 style={{ color: "#2362ba" }}>Our Presence</h6>
              <h6 style={{ fontSize: "15px" }}>
                <IoLocation style={{ color: "#2362ba" }} />
                <span
                  style={{ fontSize: "14px", fontWeight: "400" }}
                  className="mx-1"
                >
                  Bangalore, India
                </span>
              </h6>

              <a
                href="https://www.resourcetree.co.in/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src="/assets/resourceTree-logo.png"
                  alt="resourceTree"
                  style={{ height: 55, width: "auto", objectFit: "contain" }}
                  className="mt-2"
                />
              </a>
              <h5
                className="pt-4"
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                }}
              >
                <a
                  href="https://www.resourcetree.co.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  For Jobs / Careers Click Here
                </a>
              </h5>
            </Col>

            <Col md={3} className="mt-3 mt-md-0 d-flex flex-column">
              <h6 style={{ color: "#2362ba" }}>Website Insights</h6>
              <NavLink
                onClick={() => setIsServicesOpen(false)}
                to="/"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Home
              </NavLink>
              <NavLink
                onClick={() => setIsServicesOpen(false)}
                to="/about-us"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                About Us
              </NavLink>

              <div onClick={handleServicesClick}>
                <span
                  style={{ cursor: "pointer" }}
                  className={servicePath ? "activeLink" : ""}
                >
                  Services
                  {isServicesOpen ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </span>
              </div>
              {isServicesOpen && (
                <div
                  className="d-flex flex-column"
                  style={{ marginTop: "5px", gap: "3px" }}
                >
                  <NavLink
                    onClick={() => setIsServicesOpen(false)}
                    to="/organisations"
                    className={({ isActive }) =>
                      `${
                        isActive ? "activeLink" : "normalLink"
                      } d-flex align-items-center`
                    }
                  >
                    <IoMdArrowDropright />
                    For Organisations
                  </NavLink>
                  <NavLink
                    onClick={() => setIsServicesOpen(false)}
                    to="/interviewers"
                    className={({ isActive }) =>
                      `${
                        isActive ? "activeLink" : "normalLink"
                      } d-flex align-items-center`
                    }
                  >
                    <IoMdArrowDropright />
                    For Interviewers
                  </NavLink>
                  <NavLink
                    onClick={() => setIsServicesOpen(false)}
                    to="/candidates"
                    className={({ isActive }) =>
                      `${
                        isActive ? "activeLink" : "normalLink"
                      } d-flex align-items-center`
                    }
                  >
                    <IoMdArrowDropright />
                    For Candidates
                  </NavLink>
                </div>
              )}

              {/*  */}

              <NavLink
                onClick={() => setIsServicesOpen(false)}
                to="/pricing"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Pricing
              </NavLink>
              <NavLink
                onClick={() => setIsServicesOpen(false)}
                to="/blogs"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Blog
              </NavLink>
              <NavLink
                onClick={() => setIsServicesOpen(false)}
                to="/contact-us"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Contact Us
              </NavLink>
              <NavLink
                onClick={() => setIsServicesOpen(false)}
                to="/privacy-policy"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Privacy Policy
              </NavLink>
            </Col>
          </Row>
        </Container>
        <Container>
          <hr />
          <p className="text-center">
            © Copyright 2024 ResourceTree Global Services – All Rights Reserved
          </p>
        </Container>
      </div>
    </>
  );
};

export default Footer;
