import React from "react";
import styles from "./contact.module.css";
import { Button, Container } from "react-bootstrap";

const ContactStrip = () => {
  const handleOpenLogin = () => {
    window.open("https://app.improviu.co.in/signup");
  };

  return (
    <>
      <div
        className={styles.contactContainer}
        style={{
          backgroundImage: "url(/assets/contact.jpg)",
        }}
      >
        <Container className={styles.contactCard}>
          <h4>To know more contact us</h4>
          <div className="d-flex flex-wrap justify-content-center align-items-center gap-2 gap-md-3">
            <Button
              onClick={handleOpenLogin}
              variant="primary"
              className={styles.contactBtn}
            >
              Interviewer
            </Button>
            <Button
              onClick={handleOpenLogin}
              variant="success"
              className={styles.contactBtn}
            >
              Client
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ContactStrip;
