import React from "react";
import styles from "../service.module.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import BrochureDwnld from "../../../components/BrochureDwnld";
import { Helmet } from "react-helmet";
import ContactStrip from "../../../components/ContactStrip";

const ForInterviewers = () => {
  const interviewExpert = [
    { id: 1, title: "High Potential to Earn" },
    {
      id: 2,
      title:
        "The free time utilized to Interview and earn an additional income",
    },
    {
      id: 3,
      title:
        "Interviewing improves once skills better as it needs research and preparation",
    },
    { id: 4, title: "Better preparation and learning" },
    { id: 5, title: "Harnessing the potential of Gig economy" },
    { id: 6, title: "The confidential and secured interviewing process" },
    { id: 7, title: "You help many organisations scale their talent" },
    { id: 8, title: "Special badging for veteran interviewers" },
    {
      id: 9,
      title:
        "Experts can do interviews after office hours and weekends (Free time)",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Become a Freelance Interviewer: Earn & Share Your Expertise
        </title>
        <meta
          name="description"
          content="Join Improviu's network of freelance interviewers. Earn extra income, improve your skills, and contribute to hiring processes. Enjoy flexibility, expert feedback, and a supportive community."
        />
        <meta
          name="keywords"
          content="Freelance Interviewing, Gig Interviewing, Technical assessment, Free time interviewing, Earn from Interviewing "
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/interviewer.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
        <h1>For Interviewers</h1>
      </div>
      <div className="mt-4 mb-5">
        <Container>
          <p className="textJustify">
            IT Professionals who wish to spend their time in a more productive
            way by sharing their experience and expertise at the same time earn
            additionally are most welcome to be a part of Improviu
          </p>
          <div className="text-center">
            <h5 className="heading-underline mb-3">
              Improviu provides with the below benefits for Expert Interviewers
            </h5>
          </div>
        </Container>
        <div>
          <Container>
            <Row className="g-3 justify-content-center">
              {interviewExpert?.map(({ id, title }) => (
                <Col key={id} md={4} className="d-flex justify-content-center">
                  <Card className={styles.impCard}>
                    <Card.Body className="d-flex justify-content-center align-items-center text-center">
                      <Card.Title
                        style={{
                          fontSize: "14px",
                          marginBottom: "0px",
                        }}
                      >
                        {title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <p className="mt-4">
              We have over 60 Interviewers registered in the platform and over
              5000 Plus interviews completed
            </p>
            <p className="textJustify">
              What can be more exciting is that you can have a community of
              interviewers exchanging views, skills and ideas in this platform
              to improve your knowledge. Improviu provides a larger audience for
              your interactions and improvement. Welcome to be a part of the
              journey.
            </p>
          </Container>
        </div>
        <div className="mt-4">
          <BrochureDwnld />
        </div>
        <div className="mb-4">
          <ContactStrip />
        </div>
      </div>
    </>
  );
};

export default ForInterviewers;
