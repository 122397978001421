import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Error from "./pages/Error";
import ScrollToTop from "./components/ScrollToTop";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Pricing from "./pages/Pricing";
import Blogs from "./pages/Blogs";
import CandidatesAdvantage from "./pages/Blogs/CandidatesAdvantage";
import InterviewerAdvantage from "./pages/Blogs/InterviewerAdvantage";
import InterviewOutsourcing from "./pages/Blogs/InterviewOutsourcing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MockInterviews from "./pages/Blogs/MockInterviews";
import OrganisationsBenefits from "./pages/Blogs/OrganisationsBenefits";
import OutsourcingProcess from "./pages/Blogs/OutsourcingProcess";
import ForOrganisation from "./pages/Services/ForOrganisation";
import ForInterviewers from "./pages/Services/ForInterviewers";
import ForCandidates from "./pages/Services/ForCandidates";

function App() {
  return (
    <>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        
        <Route path="/organisations" element={<ForOrganisation />} />
        <Route path="/interviewers" element={<ForInterviewers />} />
        <Route path="/candidates" element={<ForCandidates />} />

        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/improviu-interview-advantages-for-candidates" element={<CandidatesAdvantage />} />
        <Route path="/blogs/improviu-interview-advantages-for-interviewer" element={<InterviewerAdvantage />} />
        <Route path="/blogs/improviu-advantages-of-interview-outsourcing" element={<InterviewOutsourcing />} />
        <Route path="/blogs/mock-interviews-improviu-help-ace-interview" element={<MockInterviews />} />
        <Route path="/blogs/10-benefits-interview-service-organisations" element={<OrganisationsBenefits />} />
        <Route path="/blogs/reasons-to-outsource-interview-process" element={<OutsourcingProcess />} />
        
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
