import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ContactStrip from "../../components/ContactStrip";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const navigate = useNavigate();

  const blogPosts = [
    {
      title:
        "The Advantages of Interview Outsourcing: Interview as a Service from Improviu",
      imgSrc: "/assets/otusourcing.png",
      url: "/blogs/improviu-advantages-of-interview-outsourcing",
      date: "ARTICLE / 19 NOV 2023",
      desc: `In today’s fast-paced world, Interview as a Service (IaaS) streamlines recruitment by outsourcing interviews. Improviu is setting a new standard for efficient and consistent hiring.`,
    },
    {
      title:
        "The Advantages of Interview as a Service from Improviu for Candidates",
      imgSrc: "/assets/service.png",
      url: "/blogs/improviu-interview-advantages-for-candidates",
      date: "ARTICLE / 15 JAN 2024",
      desc: "In today’s job market, a strong resume isn’t enough. Improviu’s Interview as a Service offers mock interviews and personalized counseling to boost interview readiness and confidence.",
    },
    {
      title:
        "The Advantages of Interview as a Service for Expert Interviewers offered by Improviu",
      imgSrc: "/assets/interviewers.png",
      url: "/blogs/improviu-interview-advantages-for-interviewer",
      date: "ARTICLE / 1 MAR 2024",
      desc: "'Interview as a Service' (Improviu) provides expert interviewers on-demand, benefiting experienced professionals with flexible opportunities.",
    },
    {
      title:
        "Ways Mock Interviews with Improviu Can Help You Ace Your Next Interview",
      imgSrc: "/assets/explaining.png",
      url: "/blogs/mock-interviews-improviu-help-ace-interview",
      date: "ARTICLE / 7 APR 2024",
      desc: "Mock interviews help candidates improve and gain confidence. Services like Interview as a Service (www.Improviu.co.in) offer expert feedback and realistic practice to prepare effectively for actual interviews.",
    },
    {
      title: "10 Ways Interview as a Service Benefits Organizations",
      imgSrc: "/assets/organisation.png",
      url: "/blogs/10-benefits-interview-service-organisations",
      date: "ARTICLE / 26 MAY 2024",
      desc: "Mock interviews help candidates improve and gain confidence. Services like Interview as a Service (www.Improviu.co.in) offer expert feedback and realistic practice to prepare effectively for actual interviews.",
    },
    {
      title: "Why would one outsource Interview process",
      imgSrc: "/assets/people-working.png",
      url: "/blogs/reasons-to-outsource-interview-process",
      date: "ARTICLE / 21 JUN 2024",
      desc: "As the global workforce evolves with technology and competition, businesses are increasingly outsourcing interviews to specialized providers. This trend helps companies streamline hiring and focus on core activities while attracting top talent.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          To know more about interviewing strategies, advantages, services and
          process, click here!!
        </title>
        <meta
          name="description"
          content="Want to learn effective interviewing strategies? Discover the benefits of outsourcing interviews with Improviu. Get expert insights and streamline your hiring process today by reading our blogs!!"
        />
        <meta
          name="keywords"
          content="Blogs, Blogs by Improviu, Information on Interview as a service, Improve the interviewing process, effective interviewing strategies, Benifits of outsourcing interviews"
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/blogs-bg.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
        <h1>Blogs</h1>
      </div>
      <div className="mt-4">
        <Container>
          <h3 className="mb-4">Blog Articles</h3>
          <Row>
            {blogPosts.map((post, index) => (
              <Col md={6} lg={4} key={index} className="mb-4">
                <Card
                  onClick={() => navigate(post.url)}
                  className="border-0 p-0"
                  style={{
                    cursor: "pointer",
                    height: "100%",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={post.imgSrc}
                    alt={`Image for ${post.title}`}
                    style={{
                      padding: "15px 15px 15px 15px",
                      borderRadius: "20px",
                      height: "180px",
                      objectFit: "cover",
                    }}
                  />

                  <Card.Body className="pt-0">
                    <span
                      style={{
                        fontSize: "12px",
                        color: "grey",
                        letterSpacing: "1px",
                      }}
                    >
                      {post.date}
                    </span>
                    <Card.Title>
                      <h2 className="fs-6 fw-bold pt-2">{post.title}</h2>
                    </Card.Title>
                    <Card.Text className="pt-2 textJustify">
                      {post.desc}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className="mt-2 mb-4">
        <ContactStrip />
      </div>
    </>
  );
};

export default Blogs;
