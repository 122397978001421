import React from "react";
import styles from "../service.module.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import BrochureDwnld from "../../../components/BrochureDwnld";
import { Helmet } from "react-helmet";
import ContactStrip from "../../../components/ContactStrip";

const ForCandidates = () => {
  const mockAdv = [
    {
      id: 1,
      title: "Realtime judgement by experts on skills, Coding and experience",
    },
    {
      id: 2,
      title:
        "Counselling by experts on important aspects of skills to be prepared",
    },
    {
      id: 3,
      title: "Self-assessment of skills and seek improvement by online courses",
    },
    { id: 4, title: "Interview recording and playback for 30 days" },
    { id: 5, title: "Recommendations on online courses and skill improvement" },
    { id: 6, title: "Assistance in resume preparation (Additional Service)" },
    {
      id: 7,
      title:
        "Suggestions on improvements by Experts on soft skills and communication",
    },
    {
      id: 8,
      title: "Tagged to a community for greater interaction and learning",
    },
    {
      id: 9,
      title:
        "Part of ResourceTree elite club for getting job post from multiple companiess",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Ace Your Interview: Practice with Improviu's Mock Interviews
        </title>
        <meta
          name="description"
          content="Prepare for your next job interview with Improviu's mock interviews. Get expert feedback, practice technical skills, and improve your confidence. Benefit from personalized coaching and career guidance."
        />
        <meta
          name="keywords"
          content="Mock Interview, Self assessment, Technical assessment, Interview feedback, Practice Interview"
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/mockInterviewer.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
        <h1>For Candidates (Mock Interview)</h1>
      </div>
      <div className="mt-4 mb-5">
        <Container>
          <p className="textJustify">
            Imagine a situation what you can answer all the questions that an
            interviewer/ hiring manager has asked you. Well, this is a way to
            get selected. Now this is a reality with Improviu mock interviews.
          </p>
          <p className="textJustify">
            Every candidate’s preparedness is ascertained in the mock interviews
            from Improviu as experts offer there interview service and also
            council you on the shortfalls you need to improve on.
          </p>
          <div>
            <Container>
              <div className="text-center">
                <h5 className="heading-underline mb-3">
                  Advantages of Mock Interview
                </h5>
              </div>
              <Row className="g-3 justify-content-center">
                {mockAdv?.map(({ id, title }) => (
                  <Col
                    key={id}
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <Card className={styles.impCard}>
                      <Card.Body className="d-flex justify-content-center align-items-center text-center">
                        <Card.Title
                          style={{
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {title}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              <p className="mt-5">
                We provide an end to end (360*) assistance in your career move
                by improving your potential and skills.
              </p>
              <p>
                Take the mock interview today to get assisted to grow your
                professional career.
              </p>
            </Container>
          </div>
        </Container>
        <div className="mt-4">
          <BrochureDwnld />
        </div>
        <div className="mb-4">
          <ContactStrip />
        </div>
      </div>
    </>
  );
};

export default ForCandidates;
