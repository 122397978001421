import React from "react";
import { Container } from "react-bootstrap";
import ContactStrip from "../../components/ContactStrip";
import BrochureDwnld from "../../components/BrochureDwnld";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us: Save Time & Money with Improviu</title>
        <meta
          name="description"
          content="Need a reliable interview outsourcing service? Improviu offers simulated interviews, expert panels, and tailored solutions to meet your hiring needs. Learn more about us"
        />
        <meta
          name="keywords"
          content="About us, Interview outsourcing, Improviu, recruitment process, Video Interview, Interview process outsourcing, Career and job opportunities, Simulated interview, Practice Interview, Expert interviewer, Self-employed interviewer,  Improve Your Hiring Process "
        />
      </Helmet>
      <div
        className="bannerImg"
        style={{
          backgroundImage: "url(/assets/about-us-bg.png)",
          height: "70vh",
        }}
      >
        <div className="imgOverlay" />
      </div>
      <div className="mt-4">
        <Container>
          <h4 className="subHeading">About Us</h4>
          <div className="mt-2">
            <p className="textJustify">
              The trend of outsourcing interviews has experienced a notable
              uptick as businesses endeavour to enhance their operational
              efficiency.
            </p>
            <p className="textJustify">
              This strategic shift allows hiring managers to reclaim valuable
              time previously spent sifting through numerous applications and
              interviewing candidates. With the adept assistance of "Improviu,"
              this arduous task is expertly managed.
            </p>
            <p className="textJustify">
              By availing themselves of outsourced interviewing services,
              companies elevate the standard of their recruitment processes.
              "Improviu – Interview as a Service" emerges as the quintessential
              solution for discerning firms, boasting a cadre of one hundred
              seasoned interviewers. Collectively, they have conducted upwards
              of 5000 interviews, delivering unparalleled benefits to clients
              including time efficiency, enhanced quality of hires, expedited
              recruitment cycles, and substantial cost savings.
            </p>
            <p className="textJustify">
              There are Interview services offerings suiting your needs, which
              will offer great relief to your hiring process.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="subHeading">
              About Improviu (Improving your interviewing experience)
            </h4>
            <p className="textJustify">
              <span className="fw-bold">
                ResourceTree Global Service Pvt Ltd
              </span>{" "}
              has been a frontrunner in providing professional talent to many
              organisations across the globe. The organisations have taken a
              step to address the key challenges and pain points of their
              clients and once such platform providing the complete interview
              outsourcing service is Improviu.
            </p>
            <p className="textJustify">
              With a vision to simplify the entire hiring process the major step
              Improviu provides is well experienced technical interviewing
              resources who provide accurate feedback and ratings for all the
              first level interviews for an organization. Over 5000 interviews
              completed the platform is proven its merits for saving time of the
              hirings manager, most cost-effective hiring process, least delays
              in the interview feedback and hiring process, 90 days of video
              back of all interviews conducted and many more wonderful
              advantages.
            </p>
            <p className="textJustify">
              This is a dream come true situation for any company to hire tech
              talent with such ease with limited cost. Improviu offers the best
              analytics on the interviews done as the hiring process can be
              improved and made more efficient.
            </p>
          </div>
        </Container>
        <div className="mt-4">
          <BrochureDwnld />
        </div>
        <div className="mb-4">
          <ContactStrip />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
